<template>
  <section>
    <a-page-header title="Политика доступов">
      <template #extra>
        <search-expandable size="large" placeholder="Поиск по названию" v-model="searchInput" @enter="handleSearch"/>
        <a-button size="large" type="primary" @click="handleShowModal">Добавить политику</a-button>
      </template>
    </a-page-header>
    <main>
      <a-table
          @change="handleTableChange"
          :pagination="pagination"
          :columns="columnsType" :data-source="list.data"
          :locale="{ emptyText: isFilterSelected ? 'Ничего не найдено' : 'Нет данных' }" :loading="loading"
          rowKey='id'>
        <template #name="{ text, record }">
          <router-link :to="{ name: 'politicView', params: { id: record.id } }" class="text-color-text">
            {{ text }}
          </router-link>
        </template>
        <template #createdAt="{ text }">
          <span>{{ moment(text, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY') }}</span>
        </template>
        <template #action="{ record }">
          <div class="action__com">
            <a-button key="edit" type="dashed" size="large"
                      @click="$router.push({ name: 'politicView', params: { id: record.id } })">
              <template #icon>
                <edit-outlined class="icons-svg"/>
              </template>
            </a-button>
            <a-button key="edit" type="dashed" size="large" @click="handleDelete(record.id)">
              <template #icon>
                <delete-outlined class="icons-svg"/>
              </template>
            </a-button>
          </div>
        </template>
      </a-table>
    </main>

    <a-modal v-model:visible="showModal" title="Создать политику доступов" @ok="showModal = false">
      <a-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
          @submit.prevent="handleCreated"
      >
        <a-form-item label="Название *" v-bind="validateInfos.name">
          <a-input v-model:value="form.name" placeholder="Введите название"/>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button key="back" size="large" class="default" @click="showModal = false">Отменить</a-button>
        <a-button key="submit" size="large" type="primary" :loading="btnLoading"
                  :disabled="!form.name"
                  @click="handleCreated">Сохранить
        </a-button>
      </template>
    </a-modal>
  </section>

</template>

<script>
import SearchExpandable from "@/components/common/SearchExpandable";
import { useStore } from 'vuex';
import {
  DELETE_RESERVATION_POLICIES,
  FETCH_RESERVATION_POLICIES,
  GET_RESERVATION_POLICIES,
  POST_RESERVATION_POLICIES,
  DELETE_RESERVATION_POLICIES_FORCE
} from "@/store/politics";
import { VIEW_PORT_HEIGHT } from "@/store/shared";
import { Modal, notification } from 'ant-design-vue';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { ref, unref } from "@vue/reactivity";
import { reactive, computed } from 'vue';
import { onMounted } from "@vue/runtime-core";
import { useForm } from '@ant-design-vue/use';
import { getReservationPolitic } from "@/compositions/politics";
import moment from "moment";

export default {
  setup() {
    const store = useStore();
    // Data;
    const showModal = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    const columnsType = ref([
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: '55%',
        slots: { customRender: 'name' },
      },
      {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'descend',
        slots: { customRender: 'createdAt' },
      },
      {
        fixed: 'right',
        className: 'text-right',
        slots: {
          customRender: 'action',
          title: 'actionTitle'
        },
      },
    ]);
    const pagination = reactive({ hideOnSinglePage: true });
    const searchInput = ref('');
    const selectedCheckboxId = ref([]);
    const currentTimeout = ref(undefined);
    const btnDisabled = ref(true);
    const isFilterSelected = ref(false);
    const { date } = getReservationPolitic();

    // Форма;
    const form = reactive({
      name: '',
    });
    const rules = reactive({
      name: [
        { required: true, message: 'Введите название', trigger: 'change' },
      ],
    });

    // computed
    const list = computed(() => store.getters[GET_RESERVATION_POLICIES]);
    const viewHeight = computed(() => store.getters[VIEW_PORT_HEIGHT]);
    const selectedFilters = reactive({
      page: 1,
      sortNameBy: undefined,
      sortCreateBy: undefined,
    });

    onMounted(async () => {
      await fetchList();
    });

    // Пагинация/фильтр/сортировка
    async function handleTableChange(pagination, filters, sorter) {
      selectedFilters.page = pagination.current;
      if (sorter) {
        selectedFilters.sortNameBy = selectedFilters.sortCreateBy = undefined;
        if (sorter?.columnKey === 'name') selectedFilters.sortNameBy = sorter.order;
        if (sorter?.columnKey === 'createdAt') selectedFilters.sortCreateBy = sorter.order;
      }
      await fetchList();
    }

    // Форма доабвить;
    const { validate, validateInfos, resetFields } = useForm(form, rules);

    const handleCreated = e => {
      e.preventDefault();
      validate().then(async () => {
        btnLoading.value = true;
        const formData = { name: form.name }
        try {
          const res = await store.dispatch(POST_RESERVATION_POLICIES, { formData });
          console.log(res);

          if (res) {
            notification.success({
              message: 'Успех',
              description: 'Запись успешно добавлена',
            });
            await fetchList();
          }

        } catch (e) {
          console.log(e)
        } finally {
          btnLoading.value = showModal.value = false;
        }
      });
    }

    function handleShowModal() {
      resetFields()
      form.name = '';
      showModal.value = true;
    }

    function onSelectChange(newSelectedRowKeys) {
      btnDisabled.value = !(newSelectedRowKeys && newSelectedRowKeys.length)
      selectedCheckboxId.value = newSelectedRowKeys;
    }

    function handleDelete(id) {
      Modal.confirm({
        title: 'Удалить политику доступа?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        onOk() {
          store.dispatch(DELETE_RESERVATION_POLICIES, id).then(async (res) => {
            if (res.id) {
              await fetchList().then(() => {
                notification.success({
                  message: 'Успех',
                  description: 'Запись успешно удалена',
                });
              });
            } else {
              if (res.errorCode === 'only_force_delete') {
                Modal.confirm({
                  title: 'Эта политика привязана к объекту. Всё равно удалить?',
                  okText: 'Да, удалить',
                  okType: 'danger',
                  cancelText: 'Нет',
                  onOk() {
                    store.dispatch(DELETE_RESERVATION_POLICIES_FORCE, id).then(async () => {
                      await fetchList().then(() => {
                        notification.success({
                          message: 'Успех',
                          description: 'Запись успешно удалена',
                        });
                      });
                    });
                  },
                });
              } else {
                Modal.info({
                  title: 'Удаление невозможно',
                  content: 'Невозможно удалить политику. Эта политика привязана к типу объекта',
                  okText: 'Отменить',
                  okType: 'default',
                });
              }
            }
          });
        },
      });
    }

    async function fetchList() {
      try {
        loading.value = true;
        isFilterSelected.value = !!selectedFilters.search;
        const res = await store.dispatch(FETCH_RESERVATION_POLICIES, unref(selectedFilters));

        pagination.pageSize = +res.meta.perPage;
        pagination.total = +res.meta.total;
        pagination.current = Math.min(+res.meta.lastPage, +res.meta.currentPage);
        selectedFilters.page = pagination.current;

      } finally {
        loading.value = false;
      }
    }

    async function handleSearch() {
      selectedFilters.search = searchInput.value;
      selectedFilters.page = 1;
      await fetchList();
    }

    return {
      moment,
      handleTableChange,
      fetchList, handleCreated, handleShowModal,
      onSelectChange, handleDelete, date,
      showModal,
      loading, btnLoading,
      columnsType, pagination,
      searchInput, handleSearch, isFilterSelected,
      selectedCheckboxId, currentTimeout, rules, form, list,
      viewHeight, validateInfos,
      resetFields,
      btnDisabled
    };
  },
  components: { SearchExpandable, EditOutlined, DeleteOutlined },
}
</script>
